@media screen and (max-width: 768px) {
    .insight-pro-wrap {
        width: 100vw !important;;
        height: 100vh !important;
        position: relative !important;
        z-index: 2;
    }
    .insight-pro-wrap figure {
        position: absolute;
        height: 100%;
    }
    .insight-pro-wrap .section-info {
        text-align: center !important;
    }
}

