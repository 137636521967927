html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
span,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
main,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  border: 0;
  margin: 0;
  padding: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block;
}

body {
  line-height: 1;
}

blockquote,
q {
  quotes: '' '';
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
}
a{
  outline:none;
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}
address {
  font-style: normal;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
  margin-bottom: 24px;
  width: 100%;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  box-sizing: border-box;
  width:100%;
  height:100%;
}

body {
  -webkit-overflow-scrolling: touch;
  font-size              : 17px;
  line-height            : 1.2;
  font-weight            : 400;
  font-family            : "SF Pro SC", "SF Pro Text", "SF Pro Icons", "PingFang SC", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  -webkit-font-smoothing : antialiased;
  -moz-osx-font-smoothing: grayscale;
  background             : #000;
  width:100%;
  height:100%;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}
*::-webkit-scrollbar { width: 0 !important }
* { -ms-overflow-style: none; }
* { overflow: -moz-scrollbars-none; }
audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}

embed,
iframe,
object {
  max-width: 100%;
}

audio:not([controls]) {
  display: none;
  height: 0;
}


ul {
  list-style: none;
  text-indent: inherit;
}

b,
strong {
  font-weight: 600;
}
a:active,
a:hover {
  outline: 0;
}


td,
th {
  padding: 0;
}

dt {
  font-weight: bold;
}

dd {
  margin: 0;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 600;
}
button {
  background: none;
  border: 0;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  color: inherit;
  cursor: pointer;
  font: inherit;
  line-height: inherit;
  overflow: visible;
  vertical-align: inherit;
  outline: none;
}